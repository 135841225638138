import React, { useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { AzureClusterPipelineData } from 'types/cluster';
import { InstancePlatform } from 'utils/platforms';
import useAzurePipeline from 'hooks/useAzurePipeline';
import { clusterFormFieldNames } from 'components/ClusterCreate/ClusterForm/ClusterForm';
interface IncortaPlatformFieldProps {
  setSelectedPlatform: (value: InstancePlatform) => void;
  azureClusterPipelineData: AzureClusterPipelineData | undefined;
  azureSupportEnabled: Boolean;
  azureSaaSEnabled: Boolean;
}

function IncortaPlatformField({
  setSelectedPlatform,
  azureClusterPipelineData,
  azureSupportEnabled,
  azureSaaSEnabled,
}: IncortaPlatformFieldProps) {
  const intl = useIntl();

  const {
    isNotConfiguredYet: hasAzurePipelineNotConfiguredYet = true,
    isLastStep: hasAzurePipelineReachedLastStep = false,
    isStatusSuccessful: isAzurePipelineSuccessful = false,
    hasError: hasAzurePipelineError = false,
    isCanceled: isAzurePipelineCanceled = false,
  } = useAzurePipeline({
    azureClusterPipelineData,
  });

  const [isAzureDisabled, setIsAzureDisabled] = useState(
    hasAzurePipelineNotConfiguredYet ||
      (!hasAzurePipelineReachedLastStep && !isAzurePipelineSuccessful),
  );

  useEffect(() => {
    setIsAzureDisabled(
      hasAzurePipelineNotConfiguredYet ||
        (!hasAzurePipelineReachedLastStep && !isAzurePipelineSuccessful),
    );
  }, [
    hasAzurePipelineNotConfiguredYet,
    hasAzurePipelineReachedLastStep,
    isAzurePipelineSuccessful,
  ]);

  function getAdditionalInfoToEnable() {
    return intl.formatMessage({
      id:
        hasAzurePipelineNotConfiguredYet ||
        hasAzurePipelineError ||
        isAzurePipelineCanceled
          ? 'clusterForm.clusterPlatformField.selfHostedIncortedOnAzure.configureFirst'
          : 'clusterForm.clusterPlatformField.selfHostedIncortedOnAzure.waitForConfigure',
    });
  }

  return (
    <Form.Item
      name={clusterFormFieldNames.platform}
      initialValue={InstancePlatform.GC}
      rules={[
        {
          required: true,
          message: intl.formatMessage({
            id: 'clusterForm.clusterPlatformField.requiredMessage',
          }),
        },
      ]}
      label={intl.formatMessage({
        id: 'clusterForm.clusterPlatformField.label',
      })}
    >
      <Select
        showSearch
        onChange={(value: string) => {
          setSelectedPlatform(value as InstancePlatform);
        }}
        placeholder={intl.formatMessage({
          id: 'clusterForm.clusterPlatformField.placeholder',
        })}
      >
        <Select.Option value={InstancePlatform.GC}>
          <FormattedMessage id="clusterForm.clusterPlatformField.incortaSaasCloud" />
        </Select.Option>
        {azureSaaSEnabled && (
          <Select.Option value={InstancePlatform.AZURE_SAAS}>
            <FormattedMessage id="clusterForm.clusterPlatformField.incortaSaasCloudAzure" />
          </Select.Option>
        )}
        {azureSupportEnabled && (
          <Select.Option
            value={InstancePlatform.AZURE}
            disabled={isAzureDisabled}
          >
            {`${intl.formatMessage({
              id: 'clusterForm.clusterPlatformField.selfHostedIncortedOnAzure.mainText',
            })}${isAzureDisabled ? getAdditionalInfoToEnable() : ''}`}
          </Select.Option>
        )}
      </Select>
    </Form.Item>
  );
}

export default IncortaPlatformField;
